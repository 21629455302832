import api from "./api";

const endPoint = "/vendors";

export const detail = (id) =>
  api
    .get(`${endPoint}/${id}`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });

export const post = (payload) =>
  api.post(`${endPoint}`, {
    name: payload.name,
    role: payload.role,
    email: payload.email,
    username: payload.username,
    password: payload.password,
    phone_number: payload.phone_number,
    address: payload.address,
  });

export const update = (payload) =>
  api.put(`${endPoint}/${payload.id}`, {
    id: payload.id,
    name: payload.user.name,
    username: payload.user.username,
    email: payload.user.email,
    phone_number: payload.user.phone_number,
    address: payload.address,
    whatsapp_number: payload.whatsapp_number,
  });

export const profileUpdate = (payload) =>
  api.put(`${endPoint}/${payload.vendor.id}`, {
    id: payload.vendor.id,
    name: payload.name,
    username: payload.username,
    email: payload.email,
    phone_number: payload.phone_number,
    address: payload.vendor.address,
    whatsapp_number: payload.vendor.whatsapp_number,
  });

export const changePassword = (payload) =>
  api.post("/users/change-password", {
    old_password: payload.old_password,
    new_password: payload.new_password,
    confirm_password: payload.confirm_password,
  });

export const editBoothType = (payload) =>
  api.post(`${endPoint}/admin/edit-vendor-booth`, {
    vendor_id: payload.vendor_id,
    booth_type_id: payload.booth_type_id,
  });

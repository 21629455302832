<template>
  <v-container fluid>
    <v-card tile flat>
      <skeleton-page-heading v-if="numberId === 10" />
      <v-card-text>
        <breadcrumb-component />
        <v-divider class="tw-mb-5"></v-divider>
        <div class="tw-flex">
          <h2
            :class="
              $vuetify.breakpoint.mdAndUp
                ? 'tw-text-gray-800 tw-text-xl tw-flex tw-font-medium tw-capitalize tw-items-center'
                : 'tw-text-gray-800 tw-text-base tw-flex tw-font-medium tw-capitalize tw-items-center'
            "
          >
            <v-icon class="tw-text-gray-800 tw-mr-2"
              >mdi-account-plus-outline</v-icon
            >
            {{ title + " " + numberId }}
          </h2>
          <v-spacer></v-spacer>

          <v-btn
            v-if="Object.keys(existBooth).length > 0"
            depressed
            class="tw-capitalize tw-tracking-wide"
            color="primary"
            :disabled="loading"
            @click="handleResetImage"
          >
            <v-icon class="tw-text-xl tw-mr-1">mdi-refresh</v-icon>
            reset image booth
          </v-btn>
        </div>
        <v-divider class="tw-mt-5"></v-divider>
      </v-card-text>
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-form @submit.prevent="triggerSend">
              <v-text-field
                :value="vendorName"
                label="Nama"
                placeholder="Nama"
                dense
                outlined
                disabled
              ></v-text-field>
              <v-autocomplete
                dense
                outlined
                item-text="name"
                item-value="id"
                label="Project"
                :disabled="loading"
                :items="data_projects"
                :error-messages="errors.project"
                v-model="form.project_id"
              ></v-autocomplete>
              <div v-if="form.project_id">
                <vue-dropzone
                  id="dropzone"
                  ref="myVueDropzone"
                  :options="dropzoneOptions"
                  @vdropzone-complete="onComplete"
                  @vdropzone-error="showError"
                  v-on:vdropzone-sending="sendingEvent"
                  :useCustomSlot="true"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      Drag and drop to upload content!
                    </h3>
                    <div class="subtitle">
                      ...or click to select a file from your computer
                    </div>
                  </div>
                </vue-dropzone>
                <v-alert
                  dense
                  border="left"
                  type="error"
                  class="mt-2"
                  v-if="errors.image"
                >
                  {{ errors.image }}
                </v-alert>
              </div>
              <div class="tw-text-right tw-mt-2">
                <v-btn
                  depressed
                  class="tw-capitalize tw-tracking-wide"
                  color="primary"
                  max-width="200"
                  width="120"
                  type="submit"
                  :loading="loading"
                  >Submit</v-btn
                >
              </div>
            </v-form>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-skeleton-loader
              v-if="Object.keys(selectedBooth).length === 0"
              class="mx-auto"
              width="250"
              type="image"
            ></v-skeleton-loader>
            <v-img contain :src="selectedBooth.booth_thumbnail">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    v-if="Object.keys(booth).length > 0"
                    class="tw-absolute tw-z-10 tw-cursor-pointer tw-border-2 tw-border-red-600 tw-border-solid"
                    :style="
                      `
              top: ${booth.top}%;
              left: ${booth.left}%;
              width: ${booth.width}%;
              height: ${booth.height}%; `
                    "
                  ></div>
                </template>
                <span>Hint width & height</span>
              </v-tooltip>
            </v-img>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {
  detailByNumber,
  detailByType,
  project,
  resetImageBooth,
  detailBoothType,
} from "@/api/booth";
import { detail } from "@/api/developer";
import page from "@/mixins/page";
import form from "@/mixins/form";
import Swal from "sweetalert2";
import BreadcrumbComponent from "../components/BreadcrumbComponent.vue";
import SkeletonPageHeading from "../components/SkeletonPageHeading.vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  mixins: [page, form],
  components: {
    BreadcrumbComponent,
    SkeletonPageHeading,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      booth: [],
      existBooth: [],
      data_projects: [],
      selectedBooth: {},
      profile: {},
      form: {
        project_id: "",
      },
      dropzoneOptions: {
        autoProcessQueue: false,
        addRemoveLinks: true,
        maxFilesize: 2,
        maxFiles: 1,
        acceptedFiles: "image/*,.svg",
        headers: {
          Authorization: "Bearer " + this.$store.state.auth.token,
          Accept: "application/json",
        },
        url: `${process.env.VUE_APP_API}/booth-details`,
      },
    };
  },
  computed: {
    numberId() {
      return this.$route.params.id;
    },
    boothTypeId() {
      return parseInt(this.$store.state.auth.profile.vendor.booth_type_id);
    },
    vendorId() {
      return parseInt(this.$store.state.auth.profile.vendor_id);
    },
    vendorName() {
      return this.$store.state.auth.profile.name;
    },
    boothCoordinateId() {
      return parseInt(this.booth.id);
    },
  },
  async mounted() {
    await this.getDetailAccount();
    await this.getBoothDetail();
    await this.getProjects();
    await this.getBoothDetailType();
    await this.getDetailBoothType();
  },
  methods: {
    async getBoothDetail() {
      const {
        data: { data },
      } = await detailByNumber({
        number_id: parseInt(this.numberId),
        booth_type_id: this.profile.booth_type_id,
      });

      this.booth = data;
    },

    async getDetailAccount() {
      const {
        data: { data },
      } = await detail(this.vendorId);
      const boothTypeId = parseInt(data.booth_type_id);
      this.profile = data;
      this.profile.booth_type_id = boothTypeId;
    },
    async getBoothDetailType() {
      const {
        data: { data },
      } = await detailByType({
        number_id: parseInt(this.numberId),
        booth_type_id: this.profile.booth_type_id,
      });
      this.existBooth = data === null ? [] : data;
    },
    async getDetailBoothType() {
      const data = await detailBoothType({ id: this.profile.booth_type_id });

      this.selectedBooth = data;
    },
    async getProjects() {
      const {
        data: { data },
      } = await project(this.vendorId);
      this.data_projects = data;
    },
    async handleResetImage() {
      try {
        this.$store.dispatch("auth/setLoading");
        await resetImageBooth({
          id: this.existBooth.id,
        });
        this.$store.dispatch("auth/setLoading");
        await Swal.fire({
          icon: "success",
          title: "Berhasil mereset gambar",
          timer: 1500,
        });

        this.$router.push({ name: "booth" });
      } catch (error) {
        console.log(error);
      }
    },
    triggerSend() {
      if (!this.form.project_id) {
        this.errors = {
          project: "Project tidak boleh kosong",
        };
      } else if (this.$refs.myVueDropzone.getQueuedFiles().length === 0) {
        this.errors = {
          image: "Upload gambar harus di isi",
        };
      } else {
        this.$store.dispatch("auth/setLoading");
        this.$refs.myVueDropzone.processQueue();
      }
    },
    sendingEvent(file, xhr, formData) {
      formData.append("project", this.form.project_id);
      formData.append("booth_coordinate", this.boothCoordinateId);
      Object.keys(this.existBooth).length === 0
        ? null
        : formData.append("booth_detail_id", this.existBooth.id);
    },
    async onComplete(response) {
      this.$store.dispatch("auth/setLoading");
      if (response.status === "success") {
        await Swal.fire({
          icon: "success",
          title: "Berhasil upload",
          timer: 1500,
        });

        this.$router.push({ name: "booth" });
      }
    },
    showError(file, message, xhr) {
      const errorMessage = JSON.parse(xhr.response).errors;
      const elements = document.querySelectorAll(".dz-error-message span");
      const lastElement = elements[elements.length - 1];
      lastElement.textContent = errorMessage;

      if (message.statusCode === 422) {
        this.errors = {
          image: message.errors,
        };
      } else {
        Swal.fire({
          icon: "error",
          title: message.message,
          timer: 1500,
        });
      }
    },
  },
};
</script>

<style>
#dropzone .dz-preview .dz-image > img {
  height: 150px !important;
  width: 100% !important;
}

.vue-dropzone > .dz-preview .dz-remove {
  margin-left: 5px !important;
  margin-right: 5px !important;
  padding: 5px 15px !important;
  left: 40px !important;
  font-size: 0.7em !important;
}

.dropzone .dz-preview .dz-error-message {
  padding: 0.5em 0.7em !important;
  font-size: 11px !important;
}
</style>

import api from "./api";

const endPointCoordinate = "/booth-coordinates";
const endPointDetail = "/booth-details";
const endPointType = "/booth-types";

export const boothTypes = () =>
  api
    .get(`${endPointType}/all`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });

export const detailBoothType = (payload) =>
  api
    .get(`${endPointType}/${payload.id}`)
    .then(function(response) {
      return response.data.data;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });

export const storeBoothType = (payload) =>
  api.post(`${endPointCoordinate}`, {
    booth_type: payload.booth_type,
    number_type: payload.number_type,
    top: payload.top,
    left: payload.left,
    width: payload.width,
    height: payload.height,
  });

export const updateBoothType = (payload) =>
  api.put(`${endPointCoordinate}/${payload.id}`, {
    booth_type: payload.booth_type_id,
    number_type: payload.number_type,
    top: payload.top,
    left: payload.left,
    width: payload.width,
    height: payload.height,
  });

export const detailCoordinate = (id) =>
  api
    .get(`${endPointCoordinate}/${id}`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });

export const coordinateByType = (payload) =>
  api
    .get(
      `${endPointCoordinate}/show-by-type/${payload.booth_type_id}?number_type=${payload.number_type}`
    )
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });

export const detailByTypes = (payload) =>
  api
    .get(`${endPointDetail}/type/${payload.booth_type_id}/developer/${payload.developer_id}`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });

export const detailByType = (payload) =>
  api
    .get(
      `${endPointDetail}/type/${payload.booth_type_id}/number/${payload.number_id}`
    )
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });

export const detailByNumber = (payload) =>
  api
    .get(
      `${endPointCoordinate}/type/${payload.booth_type_id}/number/${payload.number_id}`
    )
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });

export const project = (id) =>
  api
    .get(`/projects/by-vendor-booth-doesnt-exist/${id}`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });

export const resetImageBooth = (payload) =>
  api
    .delete(`${endPointDetail}/reset-image-booth/${payload.id}`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });
